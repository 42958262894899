import React from 'react';
import Container from '@material-ui/core/Container';

import { useStaticQuery, graphql } from 'gatsby';
import LegalLayout from '../components/LegalLayout';
import Seo from '../components/seo';

export const Head = () => <Seo title="Privacy Policy" />;

export default function Contact() {
  const {
    site: {
      siteMetadata: { title, support, company },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            support
            company
          }
        }
      }
    `,
  );
  return (
    <LegalLayout>
      <Container maxWidth="md" component="main" className="privacy">
        <h1>Privacy Policy</h1>
        <p>Version 1.1</p>
        <p>Last Update: April 26, 2023</p>
        <p>
          Thank you for using {title}. This Privacy Policy explains our
          practices regarding the collection, use, and disclosure of information
          we collect from users of our Services, including our bot and website.
          This Privacy Policy does not apply to any third-party websites,
          services, or applications, even if they are accessible through our
          Services. This Privacy Policy applies to all products and services
          offered by Plainice. By accessing or using our Services, you signify
          that you have read, understood, and agree to our collection, storage,
          use, and disclosure of your personal information as described in this
          Privacy Policy.
        </p>

        <h1>Information Collection and Use</h1>
        <p>
          Our primary goals in collecting information are to provide and improve
          our Services, to administer your use of our Services, and to enable
          you to enjoy and easily navigate our Services.
        </p>

        <h3>Information Collected Through Our Site</h3>
        <p>
          If you visit our Site, you may choose to provide us with your email
          address or other contact information in order to obtain information
          about our Services. We collect this information only for our marketing
          purposes and will not share this information with third parties.
        </p>

        <h3>Account Information</h3>
        <p>
          If you create an Account, we’ll collect certain information that can
          be used to identify you, such as your name, email address, postal
          address and phone number (“PII”). If you create an Account using your
          login credentials from a third-party single sign-on service (SSO
          Service), we’ll be able to access and collect your name, email
          address, and other PII that your privacy settings on the third-party
          service permit us to access. If you log into your account with a
          third-party SSO Service we will receive a login validation token, but
          will not receive or store your single sign-on service password. We may
          also collect your gender, date of birth, and other information that is
          not considered PII because it cannot be used by itself to identify
          you.
        </p>

        <h3>Information Collected Using Cookies and other Web Technologies</h3>
        <p>
          Like many website owners and operators, we use automated data
          collection tools such as Cookies and Web Beacons to collect certain
          information.
        </p>

        <ol>
          <li>
            “Cookies” are small text files that are placed on your hard drive by
            a Web server when you access our Services. We may use both session
            Cookies and persistent Cookies to identify that you’ve logged in to
            the Services and to tell us how and when you interact with our
            Services. We may also use Cookies to monitor aggregate usage and web
            traffic routing on our Services and to customize and improve our
            Services. Unlike persistent Cookies, session Cookies are deleted
            when you log off from the Services and close your browser. Although
            most browsers automatically accept Cookies, you can change your
            browser options to stop automatically accepting Cookies or to prompt
            you before accepting Cookies. Please note, however, that if you
            don’t accept Cookies, you may not be able to access all portions or
            features of the Services. Some third-party services providers that
            we engage (including third-party advertisers) may also place their
            own Cookies on your hard drive. Note that this Privacy Policy covers
            only our use of Cookies and does not include use of Cookies by such
            third parties.
          </li>
          <li>
            “Web Beacons” (also known as Web bugs, pixel tags or clear GIFs) are
            tiny graphics with a unique identifier that may be included on our
            Services for several purposes, including to deliver or communicate
            with Cookies, to track and measure the performance of our Services,
            to monitor how many visitors view our Services, and to monitor the
            effectiveness of our advertising. Unlike Cookies, which are stored
            on the user’s hard drive, Web Beacons are typically embedded
            invisibly on web pages (or in an email).
          </li>
          <li>
            “APIs” or application programming interfaces are software components
            that enable our Services to communicate with third-party services.
            We may use APIs to enable our Services to communicate with and
            gather information from third-party services, such as SSO Services,
            used by you.
          </li>
          <li>
            Our Site does not have the capability to respond to “Do Not Track”
            signals received from various web browsers.
          </li>
        </ol>
        <h3>Information Related to Use of Our Services</h3>
        <p>
          As part of you and your employees’ use of our Services, we will
          collect and analyze data provided to us via the various third-party
          accounts you enable for use with our Services. Because the third party
          accounts are enterprise business applications that you and your
          employees use in your day-to-day operations and communications, we may
          receive trade secrets or other confidential or proprietary information
          about your business, employees, or other highly sensitive topics that
          may be incidental to our efforts to collect information about
          organizational structure and communication as part of our Services.
        </p>

        <p>
          Our servers automatically record certain information about how you our
          Services (we refer to this information as “Log Data”), including both
          Account holders and non-Account holders. Log Data may include
          information such as your Internet Protocol (IP) address, browser type,
          operating system, the web page that you were visiting before accessing
          our Services, the pages or features of our Services to which you
          browsed and the time spent on those pages or features, search terms,
          the links on our Services that you clicked on, comments and other
          communications conducted over our Services, information from other
          third party products and services that we enable you to connect to you
          Accounts and other statistics.
        </p>

        <p>
          We use Log Data to administer the Services and we analyze (and may
          engage third parties to analyze) Log Data to improve, customize and
          enhance our Services by expanding their features and functionality and
          tailoring them to your needs and preferences. We may use your IP
          address to generate aggregate, non-identifying information about how
          our Services are used.
        </p>

        <h3>Location Information</h3>
        <p>
          When you use our services we may collect and store information about
          your location, such as by converting your IP address into a rough
          geolocation, or, if you access our Services via a mobile device, by
          accessing your mobile device’s coarse location if you enable location
          services on your device. We may use location information to improve
          and personalize our Services for you. If you do not want us to collect
          location information, you may disable that feature on your mobile
          device. In addition, some of the third-party accounts you use with our
          Services may contain your location information, which may then be
          shared with us.
        </p>

        <h3>Information Use</h3>
        <p>
          Any of the information we collect from you may be used to: *
          Personalize your experience (your information helps us to better
          respond to your individual needs) * Improve our Services (we may use
          your information and feedback to improve our Services, including our
          products, bots, and website) * Improve customer service (your
          information helps us to more effectively respond to your customer
          service requests and support needs) * Process payments * Run
          promotions, contests, surveys, or other features * Market products and
          services to you that we believe may be of interest to you * Send you
          information you agreed to receive about topics we think will be of
          interest to you * Send periodic emails with updates pertaining to our
          Services * Respond to your inquiries, questions, and/or other requests
        </p>

        <p>
          We may also use your Personal Information to create Anonymous
          Information records by excluding information that makes the
          information personally identifiable to you. We reserve the right to
          use Anonymous Information for any purpose.
        </p>

        <h1>Information We Share</h1>
        <p>
          We will not share any PII that we have collected from or regarding you
          except as described below:
        </p>

        <ol>
          <li>
            Information Shared with Our Services Providers. We may engage third
            party services providers to work with us to administer and provide
            our Services. These third party services providers have access to
            your PII only for the purpose of performing services on our behalf.
          </li>
          <li>
            Information Shared with Third Parties. We may share aggregated
            information and non-identifying information with third parties to
            enhance the effectiveness of our Services and for industry research
            and analysis, demographic profiling, benchmarking, and other similar
            purposes. As part of our Services we may share aggregate, anonymous
            demographic information, survey results, market trends, and other
            analysis that we create based on the information we receive from you
            and other customers. Such information will never identify you, your
            business, or your employees.
          </li>
          <li>
            Information Disclosed in Connection with Business Transactions.
            Information that we collect from our users, including PII, is
            considered to be a business asset. Thus, if we are acquired by a
            third party as a result of a transaction such as a merger,
            acquisition, or asset sale or if our assets are acquired by a third
            party in the event we go out of business or enter bankruptcy, some
            or all of our assets, including your PII, may be disclosed or
            transferred to a third party acquirer in connection with the
            transaction.
          </li>
          <li>
            Information Disclosed for Our Protection and the Protection of
            Others. We cooperate with government and law enforcement officials
            or private parties to enforce and comply with the law. We may
            disclose any information about you to government or law enforcement
            officials or private parties as we, in our sole discretion, believe
            necessary or appropriate: (i) to respond to claims, legal process
            (including subpoenas); (ii) to protect our property, rights and
            safety and the property, rights and safety of a third party or the
            public in general; and (iii) to stop any activity that we consider
            illegal, unethical or legally actionable activity.
          </li>
        </ol>
        <h1>Data Protection</h1>
        <p>
          We take reasonable measures to protect the information that we collect
          from you or about you (including your PII) from unauthorized access,
          use, or disclosure. Please be aware, however, that no method of
          transmitting information over the Internet or storing information is
          completely secure. Accordingly, we cannot guarantee the absolute
          security of any information.
        </p>
        <h2>How to Access and Control Your Personal Data</h2>
        <p>
          You can view, access, edit, or delete of your personal data for many
          aspects of the Services. How you can access and control your personal
          data will depend on which Services you use.
        </p>
        <p>
          You can opt out from receiving marketing communications from us by
          using the opt-out link on the communication
        </p>
        <p>
          Data Erasure. You can request that {company} delete your personal data
          by sending an email to {support} with “Please delete my personal data”
          in the subject line. {company} will verify your ability to access that
          email, then delete the personal data associated with your email
          address. We will use reasonable efforts to respond to your request
          within 14 days, but in all events within 30 days of our receipt of the
          request. Please note that we retain billing and usage metadata about a
          company or individual as required for compliance with law and
          regulation. Removing {company} from your Slack workspace is a
          prerequisite for complete deletion of your data.
        </p>
        <p>
          Data Correction. You can modify your personal data by sending an email
          to {support} with “Please update my personal data” in the subject
          line. {company} will verify your ability to access that email, then
          update the fields where possible within the Services. We will use
          reasonable efforts to respond to your request within 14 days, but in
          all events within 30 days of our receipt of the request.
        </p>
        <p>
          Your Communications Preferences. You can choose whether you wish to
          receive marketing communications from us. If you receive marketing
          communications from us and would like to opt out, you can do so by
          following the directions in that communication. Please note that these
          choices do not apply to mandatory communications that are part of the
          Services, or to surveys or other informational communications that
          have their own unsubscribe method.
        </p>
        <h3>Third Party Links</h3>
        <p>
          Occasionally, at our discretion, we may include or offer third party
          products or services on our website or through our Services. If you
          access other websites using the links provided, the operators of these
          websites may collect information from you that will be used by them in
          accordance with their privacy policies. These third party sites have
          separate and independent privacy policies. We, therefore, have no
          responsibility or liability for the content and activities of these
          linked sites. Nonetheless, we seek to protect the integrity of our
          site and welcome any feedback about these sites.
        </p>
        <h3>
          Where we Store and Process Personal Data; International Transfers
        </h3>
        <p>
          Personal data collected by {company} may be stored and processed in
          the United States or in any other country where {company} or its
          affiliates, future subsidiaries or service providers maintain
          facilities. The storage location(s) are chosen in order to operate
          efficiently, to improve performance, and to create redundancies in
          order to protect the data in the event of an outage or other problem.
          We take steps to ensure that the data we collect is processed
          according to the provisions of this Policy, and the requirements of
          applicable law wherever the data is located.
        </p>
        <p>
          We take our data privacy and security obligations seriously. Please
          see our Data Protection Addendum for more details.
        </p>
        <h3>Data Retention</h3>
        <p>
          We may retain your personal information as long as you continue to use
          the Services, have an account with us, have {title} installed on your
          Slack workspace, or for as long as is necessary to fulfill the
          purposes outlined in the policy. You can ask to close your account by
          contacting us at the details above, and we will delete your personal
          information on request.
        </p>
        <p>
          We may, however, retain personal information for an additional period
          as is permitted or required under applicable laws, for legal, tax, or
          regulatory reasons, or for legitimate and lawful business purposes.
        </p>
        <p>
          We will retain your personal data for as long as necessary to provide
          the Services to you, or for other essential purposes such as complying
          with our legal obligations, resolving disputes, and enforcing our
          agreements. Because these needs can vary for different types of data
          in the context of the different Services we provide, actual retention
          periods can vary significantly. The criteria we use to determine the
          retention periods include:
        </p>
        <p>
          How long is the personal data needed to provide the Services and/or
          operate our business? This includes such things such as maintaining
          and improving the performance of the Services, keeping our systems
          secure, and maintaining appropriate business and financial records.
          This is the general rule that establishes the baseline for most data
          retention periods.
        </p>
        <p>
          Is {company} subject to a legal, contractual, or similar obligation to
          retain the data? Examples can include mandatory data retention laws in
          the applicable jurisdiction, government orders to preserve data
          relevant to an investigation, or data that must be retained for the
          purposes of litigation.
        </p>
        <p>
          Is {title} installed on your Slack workspace? Removing {title} from
          your Slack workspace is a prerequisite for complete data deletion.
        </p>
        <h1>Modifying Your Information</h1>
        <p>
          To delete your PII and cancel your account, please send an email to
          {support}. We’ll take steps to delete your information as soon as is
          practicable, but some information may remain in archived/backup copies
          for our records or as otherwise required by law.
        </p>

        <h1>International Transfer</h1>
        <p>
          Your PII may be transferred to, and maintained on, computers located
          outside of your state, province, country, or other governmental
          jurisdiction where the privacy laws may not be as protective as those
          in your jurisdiction. If you’re located outside the United States and
          choose to provide your PII to us, we may transfer your PII to the
          United States and process it there.
        </p>

        <h1>Third Party Websites and Services</h1>
        <p>
          Our Services, including our bot and website, may contain links to
          websites and services that are owned and operated by third parties. We
          do not control the content or links that appear on these sites and are
          not responsible for the practices employed by websites and services
          linked to or from our Services. Any information that you provide on or
          to a third party website or service or that is collected by a third
          party website or service is provided directly to its owner or operator
          and is subject to the owner’s or operator’s privacy policy. To protect
          your information we recommend that you carefully review the privacy
          policies of any website or service that you access.
        </p>

        <h1>Children’s Privacy</h1>
        <p>
          Our Services are not directed to children under 13, and we do not
          knowingly collect PII from children under 13. If we learn that we have
          collected PII of a child under 13, we will take steps to delete such
          information from our files as soon as possible.
        </p>

        <h1>Revisions to this Privacy Policy</h1>
        <p>
          We reserve the right to revise this Privacy Policy from time to time.
          We will post changes on this page and indicate the “last modified”
          date at the top of this page. Please check back often for any updates.
          You acknowledge and agree that it is your responsibility to review
          this Privacy Policy periodically, and that your continued use of our
          Services after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </p>

        <h1>Your Acceptance of These Terms</h1>
        <p>
          By using our Services, you signify your acceptance of this Privacy
          Policy.
        </p>

        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at {support}.
        </p>
      </Container>
    </LegalLayout>
  );
}
